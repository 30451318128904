<template>
  <div class="container flex flex-col custom:ml-72 xl:ml-96">
    <div class="text-2xl flex flex-row mb-4">
      <img
        :src="require('../../assets/dashboard.png')"
        alt="dashboard"
        class="w-full"
      />
    </div>
    <div class="mb-2 flex flex-row justify-between items-center w-full">
      <div class="flex flex-row items-center">
        <span class="mr-2">{{ dictionary.library_filters }}</span>
        <Chip
          v-if="selectedType"
          class="mr-2"
          :label="selectedType.name"
          removable
          @remove="removeFilter('type')"
        />
        <Chip
          v-if="selectedTarget"
          class="mr-2"
          :label="selectedTarget.name"
          removable
          @remove="removeFilter('target')"
        />
        <Chip
          v-if="selectedTags"
          class="mr-2"
          :label="selectedTags.name"
          removable
          @remove="removeFilter('tags')"
        />
        <Chip
          v-if="selectedDepartment"
          class="mr-2"
          :label="selectedDepartment.name"
          removable
          @remove="removeFilter('department')"
        />
        <Chip
          v-if="selectedDuration"
          class="mr-2"
          :label="selectedDuration.name + ' min'"
          removable
          @remove="removeFilter('duration')"
        />
        <p
          v-else-if="
            !selectedType &&
            !selectedTarget &&
            !selectedTags &&
            !selectedDepartment &&
            !selectedDuration
          "
        >
          {{ dictionary.none }}
        </p>
      </div>
      <router-link
        v-if="
          selectedType ||
          selectedTarget ||
          selectedTags ||
          selectedDepartment ||
          selectedDuration
        "
        :to="{ name: 'filtered-table' }"
        @click="handleDisplayTable"
        class="dataview-btn custom:p-1 custom:text-xs custom:h-8 xl:p-2 xl:text-base xl:h-auto rounded"
      >
        <i class="pi pi-table mr-4"></i>
        <p>{{ dictionary.library_table_btn }}</p>
      </router-link>
    </div>
    <ModulesTableComponent :data="modules" />
  </div>
</template>

<script setup>
import { ref, watch, defineProps, computed, onMounted } from "vue";
import { useStore } from "../../../store/store.js";
import ModulesTableComponent from "../dashboard/table/ModulesTableComponent.vue";
import Chip from "primevue/chip";
import { dictionary } from "../../dictionary.js";
const store = useStore();
const props = defineProps({
  data: Array,
});
const modules = ref(props.data);

const selectedType = computed(() => store.selectedType);
const selectedTarget = computed(() => store.selectedTarget);
const selectedTags = computed(() => store.selectedTags);
const selectedDepartment = computed(() => store.selectedDepartment);
const selectedDuration = computed(() => store.selectedDuration);

const filterModules = () => {
  let filteredData = props.data;
  if (selectedDepartment.value) {
    filteredData = filteredData.filter(
      (item) => item.department === selectedDepartment.value.name
    );
  }
  if (selectedType.value) {
    filteredData = filteredData.filter(
      (item) => item.type === selectedType.value.name
    );
  }
  if (selectedTarget.value) {
    filteredData = filteredData.filter(
      (item) => item.target === selectedTarget.value.name
    );
  }
  if (selectedTags.value) {
    filteredData = filteredData.filter((item) =>
      item.tag.includes(selectedTags.value.name)
    );
  }
  if (selectedDuration.value) {
    filteredData = filteredData.filter(
      (item) => item.duration === selectedDuration.value.name
    );
  }

  modules.value = filteredData;
};

const handleDisplayTable = () => {
  console.log(modules.value);
  store.setFilteredModules(modules.value);
};

const removeFilter = (filter) => {
  switch (filter) {
    case "type":
      store.selectedType = null;
      break;
    case "target":
      store.selectedTarget = null;
      break;
    case "tags":
      store.selectedTags = null;
      break;
    case "department":
      store.selectedDepartment = null;
      break;
    case "duration":
      store.selectedDuration = null;
      break;
  }
};

watch(
  [
    selectedDepartment,
    selectedType,
    selectedTarget,
    selectedTags,
    selectedDuration,
  ],
  filterModules
);

onMounted(() => {
  filterModules();
});
</script>

<style scoped>
.dataview-btn {
  animation: fadeIn 0.3s ease-in forwards;
  transition: ease-in;
}
</style>