import { defineStore } from "pinia";

const generateTime = () => {
  const time = [];
  for (let i = 0.5; i <= 30; i += 0.5) {
    time.push(i);
  }
  return time;
};

export const useStore = defineStore({
  id: "data",
  state: () => ({
    data: [],
    commits: [],
    selectedModule: null,
    filteredModules: null,
    selectedType: null,
    selectedTarget: null,
    selectedTags: null,
    selectedDepartment: null,
    filterActive: false,
    admin: false,
    showEditSlotDialog: false,
    token: sessionStorage.getItem("token") || null,
    trainingElements: [
      {
        name: "Course",
      },
      {
        name: "Course + Test",
      },
      {
        name: "Course + Test + FL",
      },
    ],
    departments: [
      {
        name: "Sales",
      },
      {
        name: "Logistics",
      },
      {
        name: "HR",
      },
      {
        name: "Administration",
      },
      {
        name: "Purchase",
      },
      {
        name: "Organization",
      },
      {
        name: "Communication",
      },
      {
        name: "Others",
      },
    ],
    durations: [
      {
        name: 5,
      },
      {
        name: 10,
      },
      {
        name: 15,
      },
      {
        name: 20,
      },
      {
        name: 25,
      },
      {
        name: 30,
      },
    ],
    types: [
      {
        name: "MA",
      },
      {
        name: "FtF",
      },
      {
        name: "Nugget",
      },
      {
        name: "Video",
      },
      {
        name: "Nanolearning",
      },
      {
        name: "Other",
      },
    ],
    targets: [
      {
        name: "Store employee",
      },
      {
        name: "Store manager",
      },
      {
        name: "All store employees",
      },
      {
        name: "Warehouse employee",
      },
      {
        name: "Warehouse manager",
      },
      {
        name: "All warehouse employees",
      },
      {
        name: "Office employee",
      },
      {
        name: "Office manager",
      },
      {
        name: "All office employees",
      },
      {
        name: "District manager",
      },
      {
        name: "Other",
      },
    ],
    groups: [
      {
        name: "New",
      },
      {
        name: "Old",
      },
      {
        name: "Returning",
      },
      {
        name: "Promotion",
      },
      {
        name: "All",
      },
      {
        name: "Other",
      },
    ],
    skills: ["Basic", "Medium", "Advanced"],
    time: generateTime(),
  }),
  actions: {
    async fetchData() {
      try {
        const response = await fetch("https://eld-mapper-backend.api.prod.sit.sys.odj.cloud/mod", {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        this.data = await response.json();
      } catch (error) {
        console.error("There was a problem with the fetch operation: ", error);
      }
    },
    setSelectedModule(mod) {
      localStorage.setItem("mod", JSON.stringify(mod));
      this.selectedModule = mod;
    },
    setFilteredModules(arr) {
      this.filteredModules = arr;
    },
    setFilters(filters) {
      this.selectedType = filters.type;
      this.selectedTarget = filters.target;
      this.selectedTags = filters.tags;
      this.selectedDepartment = filters.department;
      this.selectedDuration = filters.duration;
    },
    clearFilters() {
      this.selectedType = null;
      this.selectedTarget = null;
      this.selectedTags = null;
      this.selectedDepartment = null;
      this.selectedDuration = null;
    },
    setFilterActive(value) {
      this.filterActive = value;
    },
    setToken(token) {
      this.token = token;
      sessionStorage.setItem("token", token);
    },
    clearToken() {
      this.token = null;
      sessionStorage.removeItem("token");
    },
  },
});
