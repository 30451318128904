<template>
  <div class="container flex flex-col custom:ml-72 xl:ml-96">
    <div class="w-full flex flex-row justify-between">
      <div class="mb-2 flex flex-row justify-between items-center">
        <span class="mr-2">{{ dictionary.library_filters }}</span>
        <Chip v-if="selectedType" class="mr-2" :label="selectedType.name" />
        <Chip v-if="selectedTarget" class="mr-2" :label="selectedTarget.name" />
        <Chip v-if="selectedTags" class="mr-2" :label="selectedTags.name" />
        <Chip
          v-if="selectedDepartment"
          class="mr-2"
          :label="selectedDepartment.name"
        />
        <Chip
          v-if="selectedDuration"
          class="mr-2"
          :label="selectedDuration.name + ' min'"
        />
      </div>
      <div class="flex flex-row">
        <Button
          :label="dictionary.filtered_table_reset_filters_btn"
          @click="resetFilters"
          icon="pi pi-refresh"
          class="dataview-btn custom:p-1 custom:text-xs custom:h-8 xl:p-2 xl:text-base xl:h-auto rounded mb-4 mr-4"
        />
        <Button
          :label="dictionary.filtered_export_btn"
          @click="exportCSV"
          icon="pi pi-file-export"
          class="dataview-btn custom:p-1 custom:text-xs custom:h-8 xl:p-2 xl:text-base xl:h-auto rounded mb-4"
          :disabled="disabled"
        />
      </div>
    </div>
    <DataTable
      v-model:selection="selectedModule"
      :value="filteredModules"
      class="overflow-y-scroll"
    >
      <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>

      <Column :header="dictionary.filtered_table_header_1">
        <template #body="slotProps">
          <div class="td-data">{{ slotProps.data.title }}</div>
        </template>
      </Column>

      <Column>
        <template #header>
          <Dropdown
            v-model="selectedDepartmentFilter"
            :options="departmentOptions"
            optionLabel="name"
            :placeholder="dictionary.filtered_table_header_2"
            @change="filterTable"
          />
        </template>
        <template #body="slotProps">
          <div class="td-data">{{ slotProps.data.department }}</div>
        </template>
      </Column>

      <Column>
        <template #header>
          <Dropdown
            v-model="selectedTargetFilter"
            :options="targetOptions"
            optionLabel="name"
            :placeholder="dictionary.filtered_table_header_3"
            @change="filterTable"
          />
        </template>
        <template #body="slotProps">
          <div class="td-data">{{ slotProps.data.target }}</div>
        </template>
      </Column>

      <Column :header="dictionary.filtered_table_header_4"
        ><template #body="slotProps">
          <div class="td-data">{{ slotProps.data.duration }}</div>
        </template></Column
      >
      <Column :header="dictionary.filtered_table_header_5">
        <template #body="slotProps">
          <div
            v-for="(topic, topicIndex) in slotProps.data.topic"
            :key="topicIndex"
          >
            <div
              v-for="(stopic, stopicIndex) in topic.stopic"
              :key="topicIndex + '-' + stopicIndex"
            >
              <div v-if="stopicIndex === 0" class="td-data">
                {{ topic.name }}
              </div>
              <div v-else class="td-data-blank">{{ blankString }}</div>
            </div>
          </div>
        </template>
      </Column>

      <Column :header="dictionary.filtered_table_header_6">
        <template #body="slotProps">
          <div
            v-for="(topic, topicIndex) in slotProps.data.topic"
            :key="topicIndex"
          >
            <div
              v-for="(stopic, stopicIndex) in topic.stopic"
              :key="topicIndex + '-' + stopicIndex"
            >
              <div v-if="stopicIndex === 0" class="td-data">
                {{ topic.time }}
              </div>
              <div v-else class="td-data-blank">{{ blankString }}</div>
            </div>
          </div>
        </template>
      </Column>

      <Column :header="dictionary.filtered_table_header_7">
        <template #body="slotProps">
          <div
            v-for="(topic, topicIndex) in slotProps.data.topic"
            :key="topicIndex + '-stopic'"
          >
            <div
              v-for="(stopic, stopicIndex) in topic.stopic"
              :key="topicIndex + '-' + stopicIndex"
            >
              <div class="td-data">{{ stopic.name }}</div>
            </div>
          </div>
        </template>
      </Column>

      <Column :header="dictionary.filtered_table_header_8">
        <template #body="slotProps">
          <div
            v-for="(topic, topicIndex) in slotProps.data.topic"
            :key="topicIndex + '-skill'"
          >
            <div
              v-for="(stopic, stopicIndex) in topic.stopic"
              :key="topicIndex + '-' + stopicIndex"
            >
              <div class="td-data">{{ stopic.skill }}</div>
            </div>
          </div>
        </template>
      </Column>

      <Column :header="dictionary.filtered_table_header_9">
        <template #body="slotProps">
          <div
            v-for="(topic, topicIndex) in slotProps.data.topic"
            :key="topicIndex + '-stime'"
          >
            <div
              v-for="(stopic, stopicIndex) in topic.stopic"
              :key="topicIndex + '-' + stopicIndex"
            >
              <div class="td-data">{{ stopic.time }}</div>
            </div>
          </div>
        </template>
      </Column>
      <Column field="title" header="Title" class="hidden"></Column>
      <Column field="department" header="Department" class="hidden"></Column>
      <Column field="target" header="Target" class="hidden"></Column>
      <Column field="duration" header="Duration" class="hidden"></Column>
      <Column field="topic.name" header="Topic Name" class="hidden"></Column>
      <Column field="topic.time" header="Topic Time" class="hidden"></Column>
      <Column
        field="topic.stopic.name"
        header="Subtopic Name"
        class="hidden"
      ></Column>
      <Column
        field="topic.stopic.skill"
        header="Subtopic Skill"
        class="hidden"
      ></Column>
      <Column
        field="topic.stopic.time"
        header="Subtopic Time"
        class="hidden"
      ></Column>
    </DataTable>
    <div class="total-wrapper w-full pb-4 mb-4">
      <Divider />
      <div class="w-full flex flex-row justify-end items-center">
        <span class="mr-8 custom:text-base xl:text-xl">{{
          dictionary.filtered_total
        }}</span>
        <span class="total-time mr-8 custom:text-base xl:text-xl"
          >{{ totalTime }} min.</span
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useStore } from "../../../../store/store.js";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Chip from "primevue/chip";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import { dictionary } from "../../../dictionary.js";
const store = useStore();
const filteredModules = ref(store.filteredModules);
const selectedType = computed(() => store.selectedType);
const selectedTarget = computed(() => store.selectedTarget);
const selectedTags = computed(() => store.selectedTags);
const selectedDepartment = computed(() => store.selectedDepartment);
const selectedDuration = computed(() => store.selectedDuration);
const selectedModule = ref([]);
const selectedDepartmentFilter = ref(null);
const selectedTargetFilter = ref(null);
const blankString = ref("BL4NK_");
const disabled = ref(true);

const departmentOptions = computed(() => {
  const departments = new Set();
  filteredModules.value.forEach((module) => {
    departments.add(module.department);
  });
  return Array.from(departments).map((department) => ({ name: department }));
});

const targetOptions = computed(() => {
  const targets = new Set();
  filteredModules.value.forEach((module) => {
    targets.add(module.target);
  });
  return Array.from(targets).map((target) => ({ name: target }));
});

const filterTable = () => {
  const originalModules = store.filteredModules;
  const filtered = originalModules.filter((module) => {
    const departmentMatch = selectedDepartmentFilter.value
      ? module.department === selectedDepartmentFilter.value.name
      : true;
    const targetMatch = selectedTargetFilter.value
      ? module.target === selectedTargetFilter.value.name
      : true;
    return departmentMatch && targetMatch;
  });
  filteredModules.value = filtered;
};

const resetFilters = () => {
  selectedDepartmentFilter.value = null;
  selectedTargetFilter.value = null;
  filteredModules.value = store.filteredModules;
};

const getCurrentDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}${month}${day}`;
};

const getActiveFilters = () => {
  const filters = [];
  if (selectedType.value) filters.push(selectedType.value.name.toLowerCase());
  if (selectedTarget.value)
    filters.push(selectedTarget.value.name.toLowerCase());
  if (selectedTags.value) filters.push(selectedTags.value.name.toLowerCase());
  if (selectedDepartment.value)
    filters.push(selectedDepartment.value.name.toLowerCase());
  if (selectedDuration.value)
    filters.push(selectedDuration.value.name.toLowerCase());
  return filters.join("_");
};

const formatDataForCSV = (modules) => {
  const formattedData = [];

  modules.forEach((module) => {
    module.topic.forEach((topic) => {
      topic.stopic.forEach((stopic) => {
        formattedData.push({
          title: module.title,
          department: module.department,
          target: module.target,
          duration: module.duration.toString().replace(".", ","),
          topicName: topic.name,
          topicTime: topic.time.toString().replace(".", ","),
          stopicName: stopic.name,
          stopicSkill: stopic.skill,
          stopicTime: stopic.time.toString().replace(".", ","),
        });
      });
    });
  });

  return formattedData;
};

const exportCSV = () => {
  const formattedData = formatDataForCSV(selectedModule.value);
  const currentDate = getCurrentDate();
  const activeFilters = getActiveFilters();

  const csvContent = [
    [
      "Title",
      "Department",
      "Target",
      "Duration",
      "Topic Name",
      "Topic Time",
      "Subtopic Name",
      "Subtopic Skill",
      "Subtopic Time",
    ],
    ...formattedData.map((item) => [
      item.title,
      item.department,
      item.target,
      item.duration,
      item.topicName,
      item.topicTime,
      item.stopicName,
      item.stopicSkill,
      item.stopicTime,
    ]),
  ]
    .map((e) => e.join(";"))
    .join("\n");

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", `${currentDate}_${activeFilters}_modules.csv`);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const calculateTotalTime = (modules) => {
  let totalTime = 0;
  modules.forEach((module) => {
    module.topic.forEach((topic) => {
      topic.stopic.forEach((stopic) => {
        totalTime += stopic.time;
      });
    });
  });
  return totalTime;
};

const totalTime = computed(() => calculateTotalTime(filteredModules.value));

watch(selectedModule, (newValue) => {
  disabled.value = newValue.length === 0;
});

watch([selectedDepartmentFilter, selectedTargetFilter], () => {
  filterTable();
});
</script>

<style scoped>
::v-deep .p-datatable {
  border-radius: 5px;
  background-color: #18212d;
  padding: 0.5rem;
}
::v-deep .p-datatable-thead th {
  color: #c4e9ff;
  background-color: #18212d;
  padding: 0.5rem;
}
::v-deep .p-datatable-tbody tr {
  border-bottom: 0.5rem solid #18212d;
}
::v-deep .p-datatable-tbody tr td {
  color: #c4e9ff;
  padding: 0.5rem;
  vertical-align: top;
}
.td-data {
  background-color: #111827;
  padding: 0.5rem 0.75rem;
  border-radius: 5px;
  margin-bottom: 0.25rem;
  min-height: 65px;
  display: flex;
  align-items: center;
}
.td-data-blank {
  background-color: #1f2937;
  color: #1f2937;
  padding: 0.5rem;
  border-radius: 5px;
  margin-bottom: 0.25rem;
}
.total-wrapper {
  background-color: #18212d;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.total-time {
  padding: 0.5rem 0.75rem;
  background-color: #1f2937;
  border-radius: 5px;
}
.hidden {
  display: none;
}
</style>