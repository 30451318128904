export const dictionary = {
  login: "Access",
  login_password: "Password",
  login_username: "Username",
  login_btn: "Submit",
  login_access: "Access granted.",
  logout_info: "User logged out.",
  logout: "Logout",
  menu_search: "Search module by training short name",
  sidebar_title: "mapper",
  sidebar_item1: "Dashboard",
  sidebar_item2: "Module library",
  sidebar_item3: "Filter modules",
  sidebar_item4: "Map modules",
  sidebar_item5: "Module comparator",
  sidebar_item6: "Order training",
  sidebar_item7: "Competence Profile",
  sidebar_item8: "Admin panel",
  sidebar_item9: "Documentation",
  sidebar_item10: "Report Bug",
  sidebar_lib: "Modules library",
  sidebar_doc: "Mapper documentation",
  sidebar_bug: "Report bug",
  sidebar_copy: "Copyright © e-Learning Department 2024",
  dashboard_overview: "Modules overview:",
  dashboard_mod_type: "Module type: ",
  dashboard_mod_nr: "Number of modules: ",
  dashboard_app_info: "Optimize training programs and ensure the highest quality of e-learning modules.",
  dashboard_app_info2: "Analyze common training elements, create course maps, and easily assign trainings to users.",
  dataview_title: "Our recent modules:",
  dataview_item1: "Department: ",
  dataview_item2: "Duration: ",
  dataview_item3: "Training is about: ",
  dataview_show: "Show Map",
  dataview_edit: "Edit",
  dataview_compare: "Compare",
  filter_label0: "By Departments",
  filter_label1: "By Module Type",
  filter_label2: "By Department",
  filter_label3: "By Topics",
  filter_label4: "By Module Short Name",
  filter_label5: "By Target Group",
  filter_label6: "By Key Words",
  filter_label7: "Sales",
  filter_label8: "Logistics",
  filter_label9: "HR",
  filter_label10: "Others",
  filter_label11: "By Duration",
  filter_btn_search: "Filter",
  filter_btn_clear: "Clear",
  filter_mod_found: "Modules found:",
  filter_dialog_title: "Title: ",
  filter_dialog_short: "Short: ",
  filter_dialog_duration: "Duration (min): ",
  filter_dialog_type: "Module type: ",
  filter_dialog_element: "Training elements: ",
  filter_dialog_department: "Department: ",
  filter_dialog_target: "Target: ",
  filter_dialog_employee: "Employee: ",
  filter_dialog_specification: "Training specification: ",
  filter_dialog_objective: "Objective: ",
  filter_dialog_topic: "Topic: ",
  filter_dialog_stopic: "Sub topic",
  filter_dialog_skill_lvl: "Skill level",
  filter_dialog_knowledge: "Knowledge",
  filter_dialog_knowledge_description: "Knowledge description",
  map_modules_no_trainings: "No categories to display. Select a training type to view available modules.",
  detailed_map_tags: "KEY WORDS",
  detailed_map_topics: "TOPICS",
  detailed_map_subtopics: "SUBTOPICS",
  detailed_map_info: "BASIC INFO",
  detailed_map_duration: "Duration (min): ",
  detailed_map_employees: " employees",
  detailed_info: "You can easily zoom in on the map. Just scroll the map area. To turn off the map element, move the switch below.",
  comparator_btn_compare: "Select training to compare",
  comparator_btn_select: "Add to compare",
  comparator_btn_clear: "Clear trainings",
  comparator_dropdown_label: "Select a training to compare",
  comparator_common: "Common elements",
  comparator_differences: "Differences",
  comparator_search_info: "Search by duration (number), module short name or by department.",
  comparator_remove_btn: "Remove",
  comparator_department: "Department:",
  comparator_duration: "Duration:",
  comparator_skill: "Skill:",
  comparator_min: " min.",
  comparator_type: "Type:",
  comparator_element: "Elements:",
  comparator_target: "Target:",
  comparator_emplyee: "Employees:",
  comparator_tags: "Module Tags:",
  comparator_ttag: "Topic tag:",
  comparator_stag: "Sub topic tag:",
  comparator_topics: "Topic:",
  comparator_stopics: "Sub topic:",
  library_filters: "Active filters:",
  library_table_btn: "Table view",
  filtered_export_btn: "Export selected",
  filtered_table_header_1: "Module",
  filtered_table_header_2: "Select Department",
  filtered_table_header_3: "Select Target Group",
  filtered_table_header_4: "Duration (min)",
  filtered_table_header_5: "Topics",
  filtered_table_header_6: "Time in main topic (min)",
  filtered_table_header_7: "Sub topics",
  filtered_table_header_8: "Skill level",
  filtered_table_header_9: "Time in subtopics (min)",
  filtered_table_reset_filters_btn: "Reset filters",
  filtered_total: "Total time:",
  trainings_not_found: "Trainings not found!",
  mobile_view: "The application is not available on mobile devices.",
  none: "none",
  admin_panel: "Admin panel",
  admin_panel_dialog_title: "Admin panel login",
  admin_add_module: "Add module",
  admin_close: "Close admin",
  nav_back: "Return",
  edit_header: "Manage or add a new module.",
  edit_url: "Sharepoint image link",
  edit_url_error: "The url field is invalid or empty!",
  edit_sharepoint: "The graphics should be in the SharePoint folder:",
  edit_shortname: "Training short name",
  edit_objective: "Training objective",
  edit_title: "Training full title",
  edit_department: "Department",
  edit_duration: "Duration",
  edit_type: "Training type",
  edit_elements: "Training elements",
  edit_target: "Employee target",
  edit_group: "Employee group",
  edit_create_topic: "Create topic (You can't delete the first topic)",
  edit_topic: "Enter topic",
  edit_knowledge: "Enter knowledge",
  edit_time: "Select time",
  edit_topic_warn: "Maximum number of topics reached.",
  edit_topic_btn: "Add new topic",
  edit_create_stopic: "Create sub topic (You can't delete the first sub topic)",
  edit_stopic: "Enter sub topic",
  edit_skill: "Select skill level",
  edit_stopic_warn: "Maximum number of sub topics reached.",
  edit_stopic_btn: "Add new sub topic",
  edit_create_tag: "Create key word (You can't delete the first key word)",
  edit_tag: "Enter key word",
  edit_tag_warn: "Maximum number of key words reached.",
  edit_tag_btn: "Add new key word",
  edit_toast_info: "New training created:",
  edit_error_field: "The field is invalid or empty!",
  edit_error_toast: "All fields must be filled out!",
  edit_delete_topic: "Delete topic",
  edit_delete_stopic: "Delete sub topic",
  edit_update_btn: "Update",
  edit_toast_update: "Training updated:",
};